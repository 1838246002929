/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "./Products.css";
import ProductService from "../../services/product.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import { PRODUCTS_EXCEL_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import AddProductItem from "./components/AddProductItem";
import VendorService from "../../services/vendor.services";
import TokenService from "../../services/token.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ExcelExport from "./components/ExportToExcel";
import _ from "lodash";
import VendorDiscount from "../Discount/VendorDiscount";
import VARIABLES from "../../services/variable.service";

const Products = () => {
  const token = TokenService.getUser();
  const vendorID = token?.vendor?.id;
  const vendorApporval = token?.vendorApproved?.is_approved;
  const fileRef = useRef(null);
  const imageRef = useRef(null);

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [img_url, setImageUrl] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);
  const [filter, selectFilter] = useState();
  const [action, setAction] = useState("Add Product");
  const [custom, setCustom] = useState(false);
  const [file, setSelectedFile] = useState(null);
  const [itemDetails, setItemDetails] = useState({});
  const [toggle, setToggle] = useState(false);
  const [isActivate, setActivate] = useState(false);
  const [allVendorItems, setAllVendorItem] = useState([]);
  const [vendorActionTypeToggle, setVendorActionTypeToggle] = useState("");
  const [flag, setFlag] = useState(true);
  const [lobData, setLobData] = useState([]); // state to store response data , used for filtering data
  const [lobOptions, setLobOptions] = useState([]); // state to store unique lob data
  const [searchQuery, setSearchQuery] = useState(""); // state to handle seach query
  const [lob_ID, setLob_ID] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const fetchAllDeactivatedItems = async (params) => {
    try {
      const result = await ProductService.getAllDeactivatedItemByVendorId({
        flag: flag,
        lob_id: lob_ID,
        category_id: categoryId,
      });
      if (result.data.errorCode === 0) {
        setAllVendorItem(result?.data?.data);
        setLobData(result?.data?.data);
      } else {
        setAllVendorItem([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    fetchAllDeactivatedItems();
  }, [flag, lob_ID, categoryId, lobOptions, toggle]);

  const handleFilterChange = (e) => {
    setFlag(e.target.value);
  };

  const handleClose = (val) => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setVendorActionTypeToggle("");
  };

  const handleShow = () => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      setShowModal((prev) => ({ ...prev, state: true, type: "ADD_PRODUCT" }));
    }
  };
  const handleDelete = useCallback(async (obj) => {
    try {
      const result = await ProductService.deleteItem({ item_id: obj?.item_id });
      if (result?.data?.errorCode === 0) {
        setToggle(!toggle);
        fetchAllDeactivatedItems();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  });

  const activateItem = async (obj) => {
    try {
      const result = await ProductService.activateItem({
        item_id: obj?.item_id,
      });
      if (result?.data?.errorCode === 0) {
        fetchAllDeactivatedItems();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const updateItemAvailability = async (obj) => {
    try {
      const result = await ProductService.updateItemAvailability({
        item_id: obj?.item_id,
      });
      if (result?.data?.errorCode === 0) {
        fetchAllDeactivatedItems();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleBulkUpload = (params) => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      fileRef.current.click();
    }
  };

  const handleUploadImageRef = (itemDetails) => {
    imageRef.current.click();
    setItemDetails(itemDetails);
  };

  const handleImageUpload = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("photo", selectedFile);
      formData.append("id", itemDetails?.item_id);
      formData.append("tag", "ITEM_IMAGE");
      formData.append("filename", selectedFile?.name);
      formData.append(
        "mimetype",
        selectedFile?.type === "image/jpg" ? "image/jpeg" : selectedFile?.type
      );
      const response = await ProductService.uploadProductImage(formData);
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setItemDetails({});
        fetchAllDeactivatedItems();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  };

  const handleUploadProducts = async (params) => {
    try {
      const formData = new FormData();
      formData.append("uploadfile", file);
      const response = await ProductService.uploadProductList(formData);
      if (response?.data?.errorCode === 0) {
        toast.success(response.data?.message);
        fetchAllDeactivatedItems();
        setSelectedFile(null);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(PRODUCTS_EXCEL_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Products.xlsx"
    );
  };

  const updateItems = async (data) => {
    try {
      const result = await ProductService.updateBulkUpload({
        dataArray: data,
      });
      if (result?.data?.errorCode === 0) {
        setToggle(!toggle);
        setIsSubmitShow(false);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setCustom(false);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const submitEdit = async () => {
    const updatedData = allVendorItems.filter((item) => item.is_updated);
    if (updatedData.length > 0) {
      updateItems(updatedData);
    }
  };

  const handleActionType = (val) => {
    setAction(val);
  };

  const handleImageDelete = async (itemObj) => {
    try {
      const response = await ProductService.deleteImage({
        image_name: itemObj?.image_url,
        tag: "ITEM_IMAGE",
      });
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        fetchAllDeactivatedItems();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleImageShow = (itemObj) => {
    setShowModal((prev) => ({ ...prev, state: true, type: "IMAGE" }));
    setImageUrl(itemObj?.image_url);
  };

  useEffect(() => {
    VendorService.getLobByVendorId({ vendor_id: vendorID })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setLobOptions(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    if (lob_ID !== null) {
      VendorService.getCategoryByLOBID({ lob_id: lob_ID })
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            setCategories(res?.data?.data);
          } else {
            setCategories([]);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  }, [lob_ID]);

  const handleEdit = () => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      setCustom(true);
    }
  };

  const filterDataByLob = (value) => {
    setCategoryId(null);
    setLob_ID(value);
  };

  const filterDataByCategory = (value) => {
    setCategoryId(value);
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = lobData.filter((item) => {
      return (
        item.product_name.toLowerCase().includes(query.toLowerCase()) ||
        item.sku.toLowerCase().includes(query.toLowerCase())
      );
    });
    setAllVendorItem(filtered);
  };

  const handleEnterPress = async (data) => {
    updateItems(data);
  };
  const handleExport = () => {
    ExcelExport(allVendorItems, "product.xlsx");
  };
  return (
    <>
      <LayoutContainer
        title1="Product"
        title2="Product"
        title3="Manage Your Products"
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <a
                className="beat_file_sample themeColorBlue"
                onClick={handleDownload}
                style={{ cursor: "pointer" }}
              >
                download sample product excel file
              </a>
            </div>
          </>
        }
      >
        <div className="px-1 pt-2">
          <div className="border_bottom_style">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2  text_style"
                        onChange={(e) => filterDataByLob(e.target.value)}
                      >
                        <option value="">Select Lob</option>
                        {lobOptions?.length > 0 &&
                          lobOptions?.map((item) => (
                            <>
                              <option value={item?.lob_id}>{item?.lob}</option>
                            </>
                          ))}
                      </select>
                      {/*  */}
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2  text_style"
                          onChange={(e) => filterDataByCategory(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          {categories?.length > 0 &&
                            categories?.map((item) => (
                              <>
                                <option value={item?.category_id} key={item?.category_id}>
                                  {item?.category_name}
                                </option>
                              </>
                            ))}
                        </select>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select text_style py-2"
                        onChange={(e) => handleFilterChange(e)}
                      >
                        <option value={"true"}>Activated </option>
                        <option value={"false"}>Deactivated </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control text_style py-2"
                        placeholder="Search Here......."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex gap-3 justify-content-end align-items-end">
                <div>
                  <FUSButton
                    className={"py-2"}
                    labelText={"Export to Excel"}
                    buttonType="secondary"
                    onClick={handleExport}
                  />
                </div>
                <div>
                  {!file ? (
                    <FUSButton
                      className={"py-2"}
                      labelText={"Bulk Upload"}
                      buttonType="secondary"
                      onClick={() => handleBulkUpload()}
                      title={file ? file.name : ""}
                    />
                  ) : (
                    <FUSButton
                      className={"py-2"}
                      labelText={"Upload"}
                      buttonType="primary"
                      onClick={() => handleUploadProducts()}
                      title={file ? file.name : ""}
                    />
                  )}
                </div>

                <div>
                  <FUSButton
                    className={"py-2"}
                    buttonType="primary"
                    labelText={"Add Item"}
                    onClick={handleShow}
                  />
                </div>

                {isSubmitShow ? (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Update"}
                      buttonType="primary"
                      onClick={() => submitEdit()}
                    />
                  </div>
                ) : !custom ? (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Edit Items"}
                      buttonType="primary"
                      onClick={handleEdit}
                    />
                  </div>
                ) : (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Cancel"}
                      buttonType="danger"
                      onClick={() => setCustom(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="mt-3">
              <div>
                <table className="table table-bordered border border-1 fixed-table-header">
                  <thead>
                    <tr>
                      <th>LOB</th>
                      <th>Category</th>
                      <th>Name</th>
                      <th>Discount</th>
                      <th>SKU Code</th>
                      <th>Quantity</th>
                      <th>Price per unit</th>
                      <th>Atrribute Name</th>
                      <th>Atrribute Value</th>
                      <th>Item Image</th>
                      <th>In Stock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVendorItems &&
                      allVendorItems.length > 0 &&
                      allVendorItems.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.lob_name} </td>
                              {!custom ? (
                                <td>{item.category_name} </td>
                              ) : (
                                <td>
                                  <div className="form-group">
                                    <select
                                      name="filter"
                                      id="filter"
                                      className="form-select py-2  text_style"
                                      value={item.category_name}
                                      onChange={(e) => {
                                        const selectedCategory =
                                          categories?.find(
                                            (item) =>
                                              item.category_name ===
                                              e.target.value
                                          );
                                        setAllVendorItem((prev) => {
                                          const clone = [...prev];
                                          const productId = item.product_id;
                                          // use for further need
                                          clone.forEach((product) => {
                                            if (
                                              product.product_id === productId
                                            ) {
                                              product.category_id =
                                                selectedCategory?.category_id;
                                              product.category_name =
                                                e.target.value;
                                              product.is_updated = true;
                                            }
                                          });
                                          return clone;
                                        });
                                        setIsSubmitShow(true);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          const findItem =
                                            allVendorItems?.filter(
                                              (_, idx) => index === idx
                                            );
                                          handleEnterPress(findItem);
                                        }
                                      }}
                                    >
                                      {categories?.length > 0 &&
                                        categories?.map((item) => (
                                          <>
                                            <option
                                              value={item?.category_name}
                                              // selected={item?.category_id} // may further need
                                            >
                                              {item?.category_name}
                                            </option>
                                          </>
                                        ))}
                                    </select>
                                  </div>
                                </td>
                              )}

                              {!custom ? (
                                <td>{item.product_name}</td>
                              ) : (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={item?.product_name}
                                    onChange={(e) => {
                                      setAllVendorItem((prev) => {
                                        const clone = [...prev];
                                        clone[index]["product_name"] =
                                          e.target.value;
                                        clone[index]["is_updated"] = true;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        const findItem = allVendorItems?.filter(
                                          (_, idx) => index === idx
                                        );
                                        handleEnterPress(findItem);
                                      }
                                    }}
                                  />
                                </td>
                              )}

                              <td>
                                {`${
                                  _.isNil(item?.discount_value)
                                    ? ""
                                    : `${item.discount_value} ${
                                        _.isNil(item?.discount_type)
                                          ? ""
                                          : item.discount_type === "amount"
                                          ? "Rs."
                                          : "%"
                                      }`
                                }`}
                              </td>

                              <td className="ms-3">{item.sku}</td>
                              {!custom ? (
                                <td>{parseInt(item.qty)}</td>
                              ) : (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={parseInt(item?.qty)}
                                    onChange={(e) => {
                                      setAllVendorItem((prev) => {
                                        const clone = [...prev];
                                        clone[index]["qty"] = e.target.value;
                                        clone[index]["is_updated"] = true;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        const findItem = allVendorItems?.filter(
                                          (_, idx) => index === idx
                                        );
                                        handleEnterPress(findItem);
                                      }
                                    }}
                                  />
                                </td>
                              )}

                              {!custom ? (
                                <td>{parseInt(item.price)}</td>
                              ) : (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={item?.price}
                                    onChange={(e) => {
                                      setAllVendorItem((prev) => {
                                        const clone = [...prev];
                                        clone[index]["price"] = e.target.value;
                                        clone[index]["is_updated"] = true;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        const findItem = allVendorItems?.filter(
                                          (_, idx) => index === idx
                                        );
                                        handleEnterPress(findItem);
                                      }
                                    }}
                                  />
                                </td>
                              )}

                              {item?.attr_name ? (
                                <td>
                                  {item?.attr_name.length > 0 &&
                                    item?.attr_name.map((attr) => (
                                      <tr>{attr}</tr>
                                    ))}
                                </td>
                              ) : null}

                              {item?.attr_value ? (
                                <td>
                                  {item?.attr_value.length > 0 &&
                                    item?.attr_value.map((attr) => (
                                      <tr>{attr}</tr>
                                    ))}
                                </td>
                              ) : null}
                              {/* <td>{item?.max_qty}</td> */}
                              <td>
                                {item?.image_url ? (
                                  <img
                                    src={item?.image_url}
                                    className="rounded float-start custom-image-size"
                                    onClick={() => handleImageShow(item)}
                                  />
                                ) : (
                                  <div className="col-md-auto">
                                    <FUSButton
                                      iconSrc="fa6"
                                      iconName="FaImage"
                                      iconSize={14}
                                      iconColor={"#7966e4"}
                                      className={"image_upload_style"}
                                      onClick={() => handleUploadImageRef(item)}
                                    />
                                  </div>
                                )}
                                {item?.image_url ? (
                                  <p className="ms-1">
                                    <a
                                      href="#"
                                      class="link-danger"
                                      onClick={() => handleImageDelete(item)}
                                    >
                                      Delete
                                    </a>
                                  </p>
                                ) : null}
                              </td>
                              <td>
                                <button
                                  className={` btn  btn-sm ${
                                    item?.is_available
                                      ? "btn-success"
                                      : "btn-danger"
                                  }`}
                                  onClick={() => updateItemAvailability(item)}
                                >
                                  {item?.is_available ? "Yes" : "No"}
                                </button>
                              </td>
                              <td>
                                {flag === "true" ? (
                                  <button
                                    className="btn btn-danger  btn-sm"
                                    onClick={() => handleDelete(item)}
                                  >
                                    Deactivate
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-danger  btn-sm"
                                    onClick={() => activateItem(item)}
                                  >
                                    Activate
                                  </button>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {showModal.state && (
            <FUSModal
              title={
                showModal.type === "IMAGE"
                  ? "Image"
                  : showModal.type === "VENDOR_APPROVAL"
                  ? ""
                  : action === "addProduct"
                  ? "Add Product"
                  : action === "addCategory"
                  ? "Add Category"
                  : action === "addItem"
                  ? "Add Item"
                  : "Add Product"
              }
              showModal={showModal.state}
              size={"md"}
              handleClose={() =>
                setShowModal((prev) => ({ ...prev, state: false }))
              }
              centered
            >
              {showModal.type === "IMAGE" && (
                <div className="modal_image">
                  <img
                    src={img_url}
                    alt="item Image"
                    srcset=""
                    className="custom-large-image"
                  />
                </div>
              )}
              {showModal.type === "VENDOR_APPROVAL" && (
                <h5>
                  Currently your profile is in under validation process .We will
                  notify you once it approved after that only you can access
                  this functionality.
                </h5>
              )}

              {showModal.type === "ADD_PRODUCT" && (
                <>

                    <AddProductItem
                      actionType={(val) => handleActionType(val)}
                      handleClose={(val) => handleClose(val)}
                      setFlag={setFlag}
                    />
                  
                </>
              )}
            </FUSModal>
          )}
        </div>

        <input
          type="file"
          ref={fileRef}
          value=""
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <input
          type="file"
          ref={imageRef}
          value=""
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </LayoutContainer>
    </>
  );
};

export default Products;
