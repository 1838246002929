/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import VendorService from "../../../services/vendor.services";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { pointer } from "d3-selection";
import ProductService from "../../../services/product.service";

const ManageLOB = () => {
  const [lobName, setLobName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isServiceEnabled, setIsServiceEnabled] = useState("");
  const [allLob, setAllLob] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [lobImage, setLOBImage] = useState({ image: null });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
    data: {},
  });

  const capitalizeAllLetters = (str) => {
    if (!str) return "";
    return str.toUpperCase();
  };

  const uploadImage = async (e, lob_id) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "LOB_PIC");
    formData.append("collection_data_id", lob_id);
    formData.append("filename", file.name);
    formData.append("mimetype", file.type);

    try {
      const response = await VendorService.uploadImageSignup(formData);
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setToggle((val) => !val);
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const addLOB = async (lobData) => {
    try {
      const result = await VendorService.addLOBBySuperUser(lobData);
      if (result?.data?.errorCode === 0) {
        getLOBForSuperUser();
        const successToast = toast.success(`${result.data.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setLobName("");
        setIsActive("");
        setIsServiceEnabled("");
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const lobNameUpperCase = capitalizeAllLetters(lobName);
    const isLobNameExist = allLob.some((lob) => lob.lob === lobNameUpperCase);

    if (isLobNameExist) {
      const successToast = toast.error(
        "This LOB already exists. Please use a different name.",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);

      return;
    }
    if (!lobName || !isActive || !isServiceEnabled) {
      const successToast = toast.error(
        "All fields are mandatory. Please fill in all fields.",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return;
    }
    const lobData = {
      lob: capitalizeAllLetters(lobName),
      is_active: isActive,
      is_service_enabled: isServiceEnabled,
    };

    addLOB(lobData);
  };

  const handleDeactivateLOB = async (obj) => {
    try {
      const result = await VendorService.deActivateLOBBySuperUser(obj);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleActivateLOB = async (obj) => {
    try {
      const result = await VendorService.activateLOBBySuperUser(obj);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDeactivateService = async (obj) => {
    try {
      const result = await VendorService.disableServiceBySuperUser(obj);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleActivateService = async (obj) => {
    try {
      const result = await VendorService.enableServiceBySuperUser(obj);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const deleteImage = async (item) => {
    const data = { image_name: item?.image_name, tag: item?.tag, flag: true };
    try {
      const result = await ProductService.deleteImage(data);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getLOBForSuperUser();
  }, [toggle]);

  return (
    <div>
      <div className="py-1 px-1">
        <div className="border_bottom_style pb-2 pb-md-0">
          <form onSubmit={handleSubmit}>
            <div className="row gap-2 gap-sm-0">
              <div className="col-sm-3 col-12">
                <span className="text-secondary">LOB Name</span>
                <input
                  type="text"
                  className="form-control  mt-1"
                  placeholder="Enter LOB name..."
                  value={capitalizeAllLetters(lobName)}
                  onChange={(e) =>
                    setLobName(capitalizeAllLetters(e.target.value))
                  }
                  required
                />
              </div>
              <div className="col-sm-3 col-12">
                <span className="text-secondary">Is Active</span>
                <select
                  className="form-select mt-1"
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
              <div className="col-sm-3 col-12">
                <span className="text-secondary">Is Service Enabled</span>
                <select
                  className="form-select mt-1"
                  value={isServiceEnabled}
                  onChange={(e) => setIsServiceEnabled(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
              <div className="col-md-3 d-flex align-items-center mt-2">
                <FUSButton
                  className={"py-1"}
                  labelText={"Add"}
                  type="submit"
                  buttonType="primary"
                />
              </div>
            </div>
          </form>
        </div>

        <div className="px-2 mx-1 mt-3">
          <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th>LOB Name</th>
                <th>Active</th>
                <th>Service Enabled</th>
                <th>LOB Image</th>
              </tr>
            </thead>
            <tbody>
              {allLob.length > 0 ? (
                allLob.map((item, index) => (
                  <tr key={index}>
                    <td>{item.lob}</td>
                    <td>
                      {item.is_active ? (
                        <a
                          href="#"
                          className="text-success bold"
                          onClick={() => handleDeactivateLOB(item)}
                        >
                          YES
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="text-danger bold"
                          onClick={() => handleActivateLOB(item)}
                        >
                          NO
                        </a>
                      )}
                    </td>
                    <td>
                      {item.is_service_enabled ? (
                        <a
                          href="#"
                          className="text-success bold"
                          onClick={() => handleDeactivateService(item)}
                        >
                          YES
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="text-danger bold"
                          onClick={() => handleActivateService(item)}
                        >
                          NO
                        </a>
                      )}
                    </td>
                    <td>
                      {/* <FUSButton
                        className={"py-1 px-3"}
                        labelText="Upload Image"
                        buttonType="primary"
                        type="submit"
                      /> */}
                      {item?.image_name === null ? (
                        <input
                          type="file"
                          className={`form-control mt-1`}
                          name="image"
                          onChange={(e) => uploadImage(e, item?.id)}
                        />
                      ) : (
                        <div className="d-flex gap-3">
                          <img
                            src={item?.image_name}
                            className="rounded rounded-2"
                            style={{
                              width: "45px",
                              height: "35px",
                              cursor: pointer,
                            }}
                            onClick={() =>
                              setShowModal((prev) => ({
                                ...prev,
                                state: true,
                                type: "SHOW_IMAGE",
                                data: item,
                              }))
                            }
                          />
                          <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => deleteImage(item)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No LOB data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showModal.state && (
        <FUSModal
          title={showModal.type === "SHOW_IMAGE" && "Image"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          <div>
            <img
              src={showModal?.data?.image_name}
              className="rounded rounded-2"
              style={{ width: "100%", height: "300px" }}
            />
          </div>
        </FUSModal>
      )}
    </div>
  );
};

export default ManageLOB;
