import React, { useContext, useEffect, useState } from "react";

import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import DepartmentServices from "../../../services/departmentServices";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";

const ManageCategories = () => {
  const [deptValues, setDeptValues] = useState({
    department: "",
    category: "",
    vendor_id: null,
  });
  const [deptData, setDeptData] = useState([]);
  const [category, setCategory] = useState([]);
  const [reload, setIsReload] = useState(true);
  const [customSelected, setCustomSelected] = useState(false);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const token = TokenService.getUser();

  const getAllCategories = () => {
    DepartmentServices.getCategory()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setCategory(res?.data?.data);
        } else {
          setCategory([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };
  const getAllDepartments = () => {
    DepartmentServices.getDepartment()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDeptData(res?.data?.data);
        } else {
          setDeptData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const params = {
    id: "all",
    vendor_id: "all",
  };

  const getDepartmentsAndCategories = (params) => {
    DepartmentServices.getDepartmentsAndCategories(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepartmentCategories(res?.data?.data);
        } else {
          setDepartmentCategories([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.responseHandler(error);
      });
  };

  useEffect(() => {
    getAllCategories();
    getAllDepartments();
    if (token?.role?.role_id === VARIABLES.SUPER_USER) {
      getDepartmentsAndCategories(params);
    } else {
      getDepartmentsAndCategories({});
    }
  }, [reload]);

  const handleSubmit = async () => {
    DepartmentServices.addCategory({
      department_id: deptValues.department,
      category_id: deptValues.category,
      vendor_id: deptValues?.vendor_id,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsReload((isReload) => !isReload);
          setShowModal({
            state: false,
            type: "",
          });
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
          setDeptValues({
            vendor_id: "",
            department: "",
            category: "",
          });
        } else {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.responseHandler(error);
      });
  };

  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="Manage Categories"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Category"}
            onClick={() =>
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_CATEGORY",
              }))
            }
          />
        </>
      }
    >
      <div className="px-3 py-2">
        <div className="col-md-9">
          <div>
            <table className="table table-bordered border border-1">
              <thead>
                <th>Category Name </th>
                <th>Department Name</th>
                <th>Status</th>
              </thead>
              <tbody>
                {departmentCategories?.length > 0 &&
                  departmentCategories?.map((item) => (
                    <tr>
                      <td>{item?.name}</td>
                      <td>{item?.department_name}</td>
                      <td
                        className={`${
                          item?.department_is_active
                            ? "text-success "
                            : "text-danger "
                        }`}
                      >
                        {item?.department_is_active ? "Active" : "Deactive"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={"Add Category"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setDeptValues({
              category: null,
              department: null,
              vendor_id: "",
            });
          }}
          centered
        >
          {showModal.state && (
            <div className="row">
              <div className="col-12 px-0">
                <span className="text-secondary">Department </span>
                <div>
                  <select
                    className="col-12 px-1 form-select "
                    onChange={(e) => {
                      setDeptValues((prev) => ({
                        ...prev,
                        department: e.target.value,
                      }));
                    }}
                  >
                    <option value={-1}>Select Department </option>
                    {deptData?.map((item) => (
                      <option value={item?.department_id}>
                        {item?.department_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 px-0 mt-2">
                <span className="text-secondary">Category</span>
                <div>
                  <select
                    className="col-12 px-1 form-select "
                    onChange={(e) => {
                      setDeptValues((prev) => ({
                        ...prev,
                        category: e.target.value,
                      }));
                    }}
                  >
                    <option value={-1}>Select Category</option>
                    {category?.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 mt-3 px-0">
                <FUSButton
                  labelText={"Add Category"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleSubmit()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default ManageCategories;
