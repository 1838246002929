const VENDOR_TYPE = {
  BUSINESS2BUSINESS: "B2B",
  BUSINESS2CONSUMER: "B2C",
};

const ROLES={
  PRODUCT_MANANGEMENT :'USR0020'
}

export { VENDOR_TYPE,ROLES };
