import api from "./api";

const getUIConfig = () => api.post("/getUIConfig");

const getAppUIConfigDetailsByUI_Id = (ui_id) =>
  api.post("/getAppUIConfigDetailsByUI_Id", ui_id);

const addAppUIConfigDetails = (postdata) =>
  api.post("/addAppUIConfigDetails", postdata);
const getVendorSectionDetailsById = (postdata) =>
  api.post("/getVendorSectionDetailsById", postdata);
const getSectionConfiguration = (postdata) =>
  api.post("/getSectionConfiguration", postdata);
const insertSectionVendorData = (postdata) =>
  api.post("/insertSectionVendorData", postdata);

const deleteSectionConfiguration = (postdata) =>
  api.post("/deleteSectionConfiguration", postdata);
const getLobNamebyVendorID = (postdata) =>
  api.post("/getLobNamebyVendorID", postdata);
const getProductDetailsByLobID = (postdata) =>
  api.post("/getProductDetailsByLobID", postdata);
const deleteSectionDataBySectionID = (postdata) =>
  api.post("/deleteSectionDataBySectionID", postdata);

const getSectionPositonIsExist = (data) => {
  return api.post("/getSectionPositonIsExist", data);
};
const getDiscountNameBySectionId = (data) => {
  return api.post("/getDiscountNameBySectionId", data);
};
const getProductDetailsByLobIDandSectionID = (data) => {
  return api.post("/getProductDetailsByLobIDandSectionID", data);
};
const updateOfferCardAndItemSlot = (data) => {
  return api.post("/updateOfferCardAndItemSlot", data);
};
const deleteImage = (postData) => {
  return api.post("/deleteImage", postData);
};
const updateSectionConfigration = (data) => {
  return api.post("/updateSectionConfigration", data);
};
const checkUiPositoionIsExistsData = (data) => {
  return api.post("/checkUiPositoionIsExistsData", data);
};
const checkIsPositoionExists = (data) => {
  return api.post("/checkIsPositoionExists", data);
};

const insertRewardPoints = (data) => {
  return api.post("/b2c/insertRewardsPoint", data);
};

const getUsrQuizeRewardsList = () => {
  return api.get("/getUsrQuizeRewardsList");
};

const getAllowedPositionForDropDown = (section_id) =>
  api.get(`getAllowedPositionForDropDown?section_id=${section_id}`);

const getAllQuiz = () => {
  return api.get("/b2c/quiz/getAllQuiz");
};
const addQuiz = (data) => {
  return api.post("/b2c/quiz/addQuiz", data);
};
const updateQuiz = (data) => {
  return api.post("/b2c/quiz/updateQuiz", data);
};
const updateQuizStatus = (data) => {
  return api.post("/b2c/quiz/updateQuizStatus", data);
};
const addQuestion = (data) => {
  return api.post("b2c/quiz/addQuestion", data);
};
const getAllQuestionsAndAnswers = () => {
  return api.get("b2c/quiz/getAllQuestionsAndAnswers");
};
const deleteQuestionsAndAnswers = (data) => {
  return api.post("b2c/quiz/deleteQuestionsAndAnswers", data);
};
const updateQuestionsAndAnswers = (data) => {
  return api.post("b2c/quiz/updateQuestionsAndAnswers", data);
};
const getQuestionByQuizId = (data) => {
  return api.post("b2c/quiz/getQuestionByQuizId", data);
};
const OfferService = {
  getUIConfig,
  getAppUIConfigDetailsByUI_Id,
  addAppUIConfigDetails,
  getVendorSectionDetailsById,
  getSectionConfiguration,
  insertSectionVendorData,
  deleteSectionConfiguration,
  getLobNamebyVendorID,
  getProductDetailsByLobID,
  deleteSectionDataBySectionID,
  getSectionPositonIsExist,
  getDiscountNameBySectionId,
  getProductDetailsByLobIDandSectionID,
  updateOfferCardAndItemSlot,
  deleteImage,
  updateSectionConfigration,
  checkUiPositoionIsExistsData,
  checkIsPositoionExists,
  insertRewardPoints,
  getUsrQuizeRewardsList,
  getAllowedPositionForDropDown,
  getAllQuiz,
  addQuiz,
  updateQuiz,
  updateQuizStatus,
  addQuestion,
  getAllQuestionsAndAnswers,
  deleteQuestionsAndAnswers,
  updateQuestionsAndAnswers,
  getQuestionByQuizId,
};

export default OfferService;
