import React, { useEffect, useState, useRef, useCallback } from "react";

import ProductService from "../../services/product.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import { PRODUCTS_EXCEL_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import TokenService from "../../services/token.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import _ from "lodash";
import ServiceProvider from "../../services/serviceprovider.service";
import { checkfalsyValue } from "../../validation/validation";

const Service = () => {
  const token = TokenService.getUser();
  const vendorApporval = token?.vendorApproved?.is_approved;
  const fileRef = useRef(null);
  const imageRef = useRef(null);

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [img_url, setImageUrl] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);

  const [custom, setCustom] = useState(false);
  const [file, setSelectedFile] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [flag, setFlag] = useState(true);
  const [lobData, setLobData] = useState([]); // state to store response data , used for filtering data
  const [lobOptions, setLobOptions] = useState([]); // state to store unique lob data
  const [searchQuery, setSearchQuery] = useState(""); // state to handle seach query
  const [lob_ID, setLob_ID] = useState(null);
  const [toggleType, setToggleType] = useState("addServiceItem");
  const [categoryName, setCategoryName] = useState("");
  const [allTimeSlots, setAllTimeSlot] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [selectedLOB, setSelectedLOB] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [allAttributesByCategory, setAttributesByCategory] = useState([]);
  const [productOfService, setProductOfService] = useState({
    category_id: null,
    product_name: "",
  });

  const [itemDetails, setItemDetails] = useState({
    product_id: null,
    description: null,
    sku: null,
    qty: 0,
    price: null,
    item_attributes: [],
  });

  const [errors, setErrors] = useState({});
  const [slotData, setSlotData] = useState([]);
  const MORNING = [
    { time: "06:00 AM", val: "06:00:00" },
    { time: "07:00 AM", val: "07:00:00" },
    { time: "08:00 AM", val: "08:00:00" },
    { time: "09:00 AM", val: "09:00:00" },
    { time: "10:00 AM", val: "10:00:00" },
    { time: "11:00 AM", val: "11:00:00" },
    { time: "12:00 PM", val: "12:00:00" },
  ];
  const AFTERNOON = [
    { time: "01:00 PM", val: "13:00:00" },
    { time: "02:00 PM", val: "14:00:00" },
    { time: "03:00 PM", val: "15:00:00" },
    { time: "04:00 PM", val: "16:00:00" },
  ];
  const EVENING = [
    { time: "05:00 PM", val: "17:00:00" },
    { time: "06:00 PM", val: "18:00:00" },
    { time: "07:00 PM", val: "19:00:00" },
    { time: "08:00 PM", val: "20:00:00" },
  ];
  const [selectedValues, setSelectedValues] = useState({
    MORNING: MORNING?.length > 0 ? MORNING[0].val : "",
    AFTERNOON: AFTERNOON?.length > 0 ? AFTERNOON[0].val : "",
    EVENING: EVENING?.length > 0 ? EVENING[0].val : "",
  });

  const handleChange = (event) => {
    const { name, checked, value } = event.target;

    if (checked) {
      setSlotData((prevItems) => [
        ...prevItems,
        {
          slot_name: value,
          slot_id: name,
          slots: [{ start_time: "", end_time: "", max_users: "" }],
        },
      ]);
    } else {
      setSlotData((prevItems) =>
        prevItems.filter((item) => item.slot_id !== name)
      );
    }
  };
  const handleAddSlot = (slotId) => {
    setSlotData((prev) =>
      prev.map((slot) =>
        slot.slot_id === slotId
          ? {
              ...slot,
              slots: [
                ...slot.slots,
                { start_time: "", end_time: "", max_users: "" },
              ],
            }
          : slot
      )
    );
  };
  const handleInputChange = (value, index, slotId, field) => {
    setSlotData((prev) =>
      prev.map((slot) =>
        slot.slot_id === slotId
          ? {
              ...slot,
              slots: slot.slots.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item
              ),
            }
          : slot
      )
    );
  };

  const handleDeleteSlot = (slotId, index) => {
    setSlotData((prev) =>
      prev.map((slot) =>
        slot.slot_id === slotId
          ? {
              ...slot,
              slots: slot.slots.filter((_, idx) => idx !== index),
            }
          : slot
      )
    );
  };

  const handleCategory = (e) => {
    setProductOfService((prev) => ({
      ...prev,
      category_id: e.target.value,
    }));
  };

  const addServiceByVendor = async (itemObj, slotData) => {
    try {
      const res = await ServiceProvider.insertServiceItems({
        item_details: itemObj,
        slotData,
      });
      if (res?.data?.errorCode === 0) {
        const successToast = toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setToggle(!toggle);
        setErrors({});
        handleClose();
      } else if (res?.data?.errorCode === -409) {
        const successToast = toast.warning(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmission = () => {
    const completeItemDetails = {
      ...itemDetails,
      lob_id: selectedLOB,
      category_id: productOfService.category_id,
      slotData,
    };

    const validationErrors = checkfalsyValue({
      ...completeItemDetails,
      discount: completeItemDetails?.discount_percentage,
      slots: slotData.length > 0 ? "required " : undefined,
    });
    if (_.isEmpty(validationErrors)) {
      addServiceByVendor(completeItemDetails);
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCancel = () => {
    handleClose();
    setSelectedLOB("");
    setItemDetails((prev) => ({
      ...prev,
      product_id: null,
    }));
    setProductOfService((prev) => ({
      ...prev,
      category_id: null,
      product_name: "",
    }));
  };

  const getProductCategoryByLOBID = useCallback(async () => {
    if (selectedLOB) {
      try {
        const result = await ProductService.getProductCategoryByLOBID(
          selectedLOB
        );
        setProductCategory([]);
        if (result?.data?.errorCode === 0) {
          setProductCategory(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [selectedLOB]);

  const getAllProductsByCategoryId = useCallback(async () => {
    if (productOfService?.category_id) {
      try {
        const result = await ProductService.getAllProductsByCategoryId({
          category_id: parseInt(productOfService.category_id),
          vendor_id: token?.agent?.agent_user_id,
        });
        setAllProducts([]);
        if (result?.data?.errorCode === 0) {
          setAllProducts(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [productOfService?.category_id]);

  const getAttributesByCategory = useCallback(async () => {
    if (productOfService?.category_id) {
      try {
        const result = await ServiceProvider.getAttributesForService({
          category_id: productOfService.category_id,
        });
        setAttributesByCategory([]);
        if (result?.data?.errorCode === 0) {
          setAttributesByCategory(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [productOfService?.category_id]);

  const getAllTimeSlot = async () => {
    try {
      const result = await ServiceProvider.getSlotsForService();
      if (result?.data?.errorCode === 0) {
        setAllTimeSlot(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  useEffect(() => {
    getAllTimeSlot();
  }, []);

  useEffect(() => {
    getProductCategoryByLOBID();
  }, [getProductCategoryByLOBID, selectedLOB]);

  useEffect(() => {
    getAllProductsByCategoryId();
  }, [productOfService?.category_id]);

  useEffect(() => {
    getAttributesByCategory();
  }, [productOfService?.category_id]);

  const handleSaveCategory = async (params) => {
    const categoryDetails = {
      lob_id: selectedLOB,
      category_name: categoryName,
    };
    const validationErrors = checkfalsyValue({
      ...categoryDetails,
      lob: categoryDetails?.lob_id,
    });
    if (!_.isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    } else {
      try {
        const result = await ProductService.addProductCategoryByLOBID({
          lob_id: selectedLOB,
          category_name: categoryName,
        });
        if (result.data.errorCode === 0) {
          setCategoryName("");
          setSelectedLOB("");
          handleClose();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setErrors({});
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleAddProduct = async () => {
    const completeProductDetails = {
      ...productOfService,
      lob_id: selectedLOB,
      is_service_enabled: true,
    };
    const validationErrors = checkfalsyValue({
      ...completeProductDetails,
      lob: completeProductDetails?.lob_id,
      category: completeProductDetails?.category_id,
    });
    if (_.isEmpty(validationErrors)) {
      try {
        const result = await ServiceProvider.addProductOfService(
          completeProductDetails
        );
        if (result?.data?.errorCode === 0) {
          toast.success(result?.data?.message);
          handleClose();
          setErrors({});
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const fetchAllServices = async (params) => {
    try {
      const result = await ServiceProvider.getServiceItemsByVendorId({
        flag,
        lob_id: lob_ID,
      });
      setAllServices([]);
      setLobData([]);
      if (result.data.errorCode === 0) {
        setAllServices(result?.data?.data);
        setLobData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    fetchAllServices();
  }, [flag, lob_ID, toggle]);

  const handleFilterChange = (e) => {
    setFlag(e.target.value);
  };

  const handleClose = (val) => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setSelectedLOB("");
    setToggleType("addServiceItem");
    setItemDetails((prev) => ({
      ...prev,
      product_id: null,
    }));
    setProductOfService({
      category_id: null,
      product_name: "",
    });
    setItemDetails({
      product_id: null,
      description: null,
      sku: null,
      qty: 0,
      price: null,
      item_attributes: [],
    });
  };

  const handleShow = () => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      setShowModal((prev) => ({ ...prev, state: true, type: "ADD_SERVICE" }));
    }
  };
  const handleDelete = async (obj) => {
    try {
      const result = await ProductService.deleteItem({ item_id: obj?.item_id });
      if (result?.data?.errorCode === 0) {
        setToggle(!toggle);
        fetchAllServices();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const activateItem = async (obj) => {
    try {
      const result = await ProductService.activateItem({
        item_id: obj?.item_id,
      });
      if (result?.data?.errorCode === 0) {
        fetchAllServices();
        setToggle(!toggle);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  // Handle Bulk Upload Using Excel File
  const handleBulkUpload = (params) => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      fileRef.current.click();
    }
  };

  const handleUploadImageRef = (itemDetails) => {
    imageRef.current.click();
    setItemDetails(itemDetails);
  };

  const handleImageUpload = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("photo", selectedFile);
      formData.append("id", itemDetails?.item_id);
      formData.append("tag", "SERVICE_ITEM_IMAGE");
      formData.append("filename", selectedFile?.name);
      formData.append(
        "mimetype",
        selectedFile?.type === "image/jpg" ? "image/jpeg" : selectedFile?.type
      );
      const response = await ProductService.uploadProductImage(formData);
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setItemDetails({});
        fetchAllServices();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  };

  const handleUploadProducts = async (params) => {
    try {
      const formData = new FormData();
      formData.append("uploadfile", file);
      const response = await ProductService.uploadProductList(formData);
      if (response?.data?.errorCode === 0) {
        toast.success(response.data?.message);
        setSelectedFile(null);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(PRODUCTS_EXCEL_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Products.xlsx"
    );
  };

  const submitEdit = async () => {
    try {
      const result = await ProductService.updateBulkUpload({
        dataArray: allServices,
      });
      if (result?.data?.errorCode === 0) {
        setToggle(!toggle);
        setIsSubmitShow(false);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setCustom(false);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleImageDelete = async (itemObj) => {
    try {
      const response = await ProductService.deleteImage({
        image_name: itemObj?.image_url,
        tag: "ITEM_IMAGE",
      });
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        fetchAllServices();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleImageShow = (itemObj) => {
    setShowModal((prev) => ({ ...prev, state: true, type: "IMAGE" }));
    setImageUrl(itemObj?.image_url);
  };

  useEffect(() => {
    ServiceProvider.getLOBForService({ lobType: "SERVICE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const data = res?.data?.data?.filter(
            (item) => item?.is_service_enabled
          );
          setLobOptions(data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const handleEdit = () => {
    if (!vendorApporval) {
      setShowModal((prev) => ({
        ...prev,
        state: true,
        type: "VENDOR_APPROVAL",
      }));
    } else {
      setCustom(true);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = lobData.filter((item) => {
      return (
        item.product_name.toLowerCase().includes(query.toLowerCase()) ||
        item.sku.toLowerCase().includes(query.toLowerCase())
      );
    });
    setAllServices(filtered);
  };

  // Use for further need to check time
  // slotData?.map((item, index) => {
  //   if (item?.start_time < item?.end_time) {
  //     slotData[index].isActive = true;
  //   } else {
  //     return (item.isActive = false);
  //   }
  // });
  return (
    <>
      <LayoutContainer
        title1="Service"
        title2="Service"
        title3="Manage Your Services"
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <a
                className="beat_file_sample themeColorBlue"
                onClick={handleDownload}
                style={{ cursor: "pointer" }}
              >
                download sample product excel file
              </a>
            </div>
          </>
        }
      >
        <div className="px-1 pt-2">
          <div className="border_bottom_style">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2  text_style"
                        onChange={(e) => setLob_ID(e.target.value)}
                      >
                        <option value="">Select Lob</option>
                        {lobOptions?.length > 0 &&
                          lobOptions?.map((item) => (
                            <>
                              <option value={item?.id}>{item?.lob}</option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select text_style py-2"
                        onChange={(e) => {
                          setFlag((val) => !val);
                          setAllServices([]);
                        }}
                      >
                        <option value={true}>Activated </option>
                        <option value={false}>Deactivated </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control text_style py-2"
                        placeholder="Search Here......."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex gap-3 justify-content-end align-items-end">
                {/* 
                This code will be use for further future need to upload services in bulk
                <div>
                  {!file ? (
                    <FUSButton
                      className={"py-2"}
                      labelText={"Bulk Upload"}
                      buttonType="secondary"
                      onClick={() => handleBulkUpload()}
                      title={file ? file.name : ""}
                    />
                  ) : (
                    <FUSButton
                      className={"py-2"}
                      labelText={"Upload"}
                      buttonType="primary"
                      onClick={() => handleUploadProducts()}
                      title={file ? file.name : ""}
                    />
                  )}
                </div> */}
                <div>
                  <FUSButton
                    className={"py-2"}
                    buttonType="primary"
                    labelText={"Add Service"}
                    onClick={handleShow}
                  />
                </div>

                {isSubmitShow ? (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Modify"}
                      buttonType="primary"
                      onClick={() => submitEdit()}
                    />
                  </div>
                ) : !custom ? (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Edit Items"}
                      buttonType="primary"
                      onClick={handleEdit}
                    />
                  </div>
                ) : (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Cancel"}
                      buttonType="danger"
                      onClick={() => setCustom(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="mt-3">
              <div>
                <table className="table table-bordered border border-1">
                  <thead>
                    <tr>
                      <th>Line of Business</th>
                      <th>Category</th>
                      <th>Name</th>
                      <th>SKU Code</th>
                      <th>Price</th>

                      <th>Item Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allServices &&
                      allServices.length > 0 &&
                      allServices.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.lob_name} </td>
                              <td>{item?.category_name}</td>
                              <td>{item.product_name} </td>

                              {/* <td>
                                {`${
                                  _.isNil(item?.discount_value)
                                    ? ""
                                    : `${item.discount_value} ${
                                        _.isNil(item?.discount_type)
                                          ? ""
                                          : item.discount_type === "amount"
                                          ? "Rs."
                                          : "%"
                                      }`
                                }`}
                              </td> */}

                              <td>{item.sku}</td>
                              {/* {!custom ? (
                                <td>{parseInt(item.qty)}</td>
                              ) : (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={parseInt(item?.qty)}
                                    onChange={(e) => {
                                      setAllServices((prev) => {
                                        const clone = [...prev];
                                        clone[index]["qty"] = e.target.value;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                  />
                                </td>
                              )} */}

                              {!custom ? (
                                <td>{parseInt(item.price)}</td>
                              ) : (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={item?.price}
                                    onChange={(e) => {
                                      setAllServices((prev) => {
                                        const clone = [...prev];
                                        clone[index]["price"] = e.target.value;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                  />
                                </td>
                              )}

                              <td>
                                {item?.image_url ? (
                                  <img
                                    src={item?.image_url}
                                    alt="placeholder"
                                    className="rounded float-start custom-image-size"
                                    onClick={() => handleImageShow(item)}
                                  />
                                ) : (
                                  <div className="col-md-auto">
                                    <FUSButton
                                      iconSrc="fa6"
                                      iconName="FaImage"
                                      iconSize={14}
                                      iconColor={"#7966e4"}
                                      className={"image_upload_style"}
                                      onClick={() => handleUploadImageRef(item)}
                                    />
                                  </div>
                                )}
                                {item?.image_url ? (
                                  <p className="ms-1">
                                    <a
                                      href="#"
                                      class="link-danger"
                                      onClick={() => handleImageDelete(item)}
                                    >
                                      Delete
                                    </a>
                                  </p>
                                ) : null}
                              </td>

                              <td>
                                {flag === true ? (
                                  <div className="row justify-content-start">
                                    <div className="col-md-auto">
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(item)}
                                      >
                                        Deactivate
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => activateItem(item)}
                                  >
                                    Activate
                                  </button>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {showModal.state && (
            <FUSModal
              title={
                showModal.type === "VENDOR_APPROVAL"
                  ? ""
                  : toggleType === "addCategory"
                  ? "Add Category"
                  : toggleType === "addService"
                  ? "Add Service"
                  : "Add Service Item"
              }
              showModal={showModal.state}
              size={"lg"}
              handleClose={() => {
                handleClose();
                setErrors({});
              }}
              centered
            >
              {showModal.type === "IMAGE" && (
                <div className="modal_image">
                  <img
                    src={img_url}
                    alt="item Image"
                    srcset=""
                    className="custom-large-image"
                  />
                </div>
              )}
              {showModal.type === "VENDOR_APPROVAL" && (
                <h5>
                  Currently your profile is in under validation process .We will
                  notify you once it approved after that only you can access
                  this functionality.
                </h5>
              )}

              {showModal.type === "ADD_SERVICE" && (
                <>
                  <div>
                    {toggleType === "addCategory" ? (
                      <>
                        <div className="row px-0 mt-3">
                          <div className="col-md-12 px-0 m-0 ">
                            <div>
                              <span className="text-secondary">Select LOB</span>
                              <select
                                id="lob"
                                className="form-select mt-1 text_style"
                                value={selectedLOB}
                                onChange={(e) => setSelectedLOB(e.target.value)}
                              >
                                <option value="">Select</option>
                                {lobOptions.length > 0 &&
                                  lobOptions.map((lob, index) => (
                                    <option key={index} value={lob.id}>
                                      {lob.lob}
                                    </option>
                                  ))}
                              </select>
                              {_.isEmpty(selectedLOB) ||
                              _.isNil(selectedLOB) ? (
                                <span className="error-text">
                                  {errors?.lob}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12 px-0 mt-2">
                            <div>
                              <label className="text-secondary">
                                Category Name
                              </label>
                              <input
                                type="text"
                                className="form-control text_style mt-1"
                                id="exampleFormControlInput1"
                                placeholder="Enter here..."
                                value={categoryName}
                                onChange={(e) =>
                                  setCategoryName(e.target.value)
                                }
                              />
                              {_.isEmpty(categoryName) ||
                              _.isNil(categoryName) ? (
                                <span className="error-text">
                                  {errors?.category_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mt-1">
                          <FUSButton
                            className={"form-control py-2 mt-3"}
                            labelText={"Save"}
                            buttonType="primary"
                            onClick={() => handleSaveCategory()}
                          />
                        </div>
                      </>
                    ) : toggleType === "addServiceItem" ? (
                      <>
                        <div className="row">
                          <div className="col-md-12 px-0 m-0 ">
                            <div>
                              <span className="text-secondary">Select LOB</span>
                              <select
                                id="lob"
                                className="form-select mt-1 text_style"
                                value={selectedLOB}
                                onChange={(e) => setSelectedLOB(e.target.value)}
                              >
                                <option value="">Select</option>
                                {lobOptions.length > 0 &&
                                  lobOptions.map((lob, index) => (
                                    <option key={index} value={lob.id}>
                                      {lob.lob}
                                    </option>
                                  ))}
                              </select>
                              <span className="text-danger">
                                {errors.lob_id}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 px-0 m-0">
                            <div class="form-group">
                              <label>Select Category</label>
                              <select
                                id="subCategory"
                                className="form-select mt-1 text_style"
                                value={productOfService.category_id}
                                onChange={(e) => handleCategory(e)}
                                disabled={!selectedLOB}
                              >
                                <option value="">Select</option>
                                {productCategory.map((item) => (
                                  <option
                                    key={item.category_name}
                                    value={item.category_id}
                                  >
                                    {item.category_name}
                                  </option>
                                ))}
                              </select>
                              <span className="text-danger">
                                {errors.category_id}
                              </span>
                            </div>
                            <div className="float-end mx-2 mt-1">
                              <a
                                className="color_style"
                                href="#"
                                onClick={(e) => setToggleType("addCategory", e)}
                              >
                                Add category
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* service  */}
                        <div className="mt-2">
                          <div className="row justify-content-between">
                            <div className="col-md-12 px-0 m-0">
                              <div class="form-group">
                                <label>Select Service Item</label>
                                <select
                                  id="service"
                                  className="form-select"
                                  value={itemDetails.product_id}
                                  onChange={(e) => {
                                    setSlotData([]);
                                    setItemDetails((prev) => ({
                                      ...prev,
                                      product_id: e.target.value,
                                    }));
                                  }}
                                  disabled={!productOfService.category_id}
                                >
                                  <option value="">Select</option>
                                  {allProducts.map((item) => (
                                    <option key={item.name} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {_.isEmpty(itemDetails.product_id) ||
                                _.isNil(itemDetails.product_id) ? (
                                  <span className="error-text">
                                    {errors?.product_id}
                                  </span>
                                ) : null}
                              </div>
                              <div className="float-end mx-2 mt-1">
                                <a
                                  className="color_style"
                                  href="#"
                                  onClick={(e) =>
                                    setToggleType("addService", e)
                                  }
                                >
                                  Add Service
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {itemDetails.product_id ? (
                          <>
                            <div className="row justify-content-between">
                              <div className="col-md-6 ps-0 m-0">
                                <div className="form-group">
                                  <label htmlFor="">SKU Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="skuCode"
                                    id="sku-code"
                                    value={itemDetails.sku}
                                    onChange={(e) =>
                                      setItemDetails((prev) => ({
                                        ...prev,
                                        sku: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                                {_.isEmpty(itemDetails.sku) ||
                                _.isNil(itemDetails.sku) ? (
                                  <span className="error-text">
                                    {errors?.sku}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-md-6 ps-0 m-0">
                                <div className="form-group">
                                  <label htmlFor="">Price</label>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    value={itemDetails.price}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      e.target.value = value.slice(0, 5);
                                      setItemDetails((prev) => ({
                                        ...prev,
                                        price: e.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                                {_.isEmpty(itemDetails.price) ||
                                _.isNil(itemDetails.price) ? (
                                  <span className="error-text">
                                    {errors?.price}
                                  </span>
                                ) : null}
                              </div>
                            </div>

                            <div className="row justify-content-between">
                              <div className="col-md-6 ps-0 m-0">
                                <div className="form-group">
                                  <label htmlFor="">Tax</label>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    value={itemDetails.tax}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      e.target.value = value.slice(0, 3);
                                      setItemDetails((prev) => ({
                                        ...prev,
                                        tax: e.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                                {_.isEmpty(itemDetails.tax) ||
                                _.isNil(itemDetails.tax) ? (
                                  <span className="error-text">
                                    {errors?.tax}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-md-6 ps-0 m-0">
                                <div className="form-group">
                                  <label htmlFor="">Discount</label>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    value={itemDetails.discount_percentage}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      e.target.value = value.slice(0, 5);
                                      setItemDetails((prev) => ({
                                        ...prev,
                                        discount_percentage: e.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                                {_.isEmpty(itemDetails.discount_percentage) ||
                                _.isNil(itemDetails.discount_percentage) ? (
                                  <span className="error-text">
                                    {errors?.discount}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-md-12 ps-0 m-0">
                                <div className="form-group">
                                  <label htmlFor="">Select time Slot</label>
                                  {!_.isEmpty(allTimeSlots) ? (
                                    <div className="row">
                                      {allTimeSlots?.map((option, index) => (
                                        <div className="col-md-auto">
                                          <div className="row">
                                            <input
                                              type="checkbox"
                                              className="col-md-auto form-check"
                                              name={option.slot_id}
                                              value={option.slot_name}
                                              checked={
                                                !!slotData.find(
                                                  (item) =>
                                                    item.slot_id ===
                                                    option.slot_id
                                                )
                                              }
                                              onChange={handleChange}
                                            />

                                            <p className="col-md-auto mt-3">
                                              {option?.slot_name}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                                {_.isEmpty(itemDetails.slots) ||
                                _.isNil(itemDetails.slots) ? (
                                  <span className="error-text">
                                    {errors?.slots}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="row justify-content-between">
                              {!_.isEmpty(slotData) ? (
                                <>
                                  {slotData?.map((slot, slotIndex) => (
                                    <div className="mt-3 p-0">
                                      <div className="row justify-content-between">
                                        <div className="col-md-6">
                                          <p className="fw-bold">
                                            {slot?.slot_name}
                                          </p>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                          <FUSButton
                                            className={"action_button_style"}
                                            iconSrc="md"
                                            iconName="MdAdd"
                                            iconSize={16}
                                            buttonType="primary"
                                            onClick={() =>
                                              handleAddSlot(slot.slot_id)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row justify-content-between">
                                        {!_.isEmpty(slot.slots) &&
                                          slot.slots.map((item, index) => (
                                            <div
                                              key={index}
                                              className="row justify-content-between p-0"
                                            >
                                              <div className="col-md-4">
                                                <label htmlFor="">
                                                  Start Time
                                                </label>
                                                <select
                                                  className="form-select"
                                                  value={
                                                    slot.slot_id ===
                                                    "SLT0000001"
                                                      ? selectedValues.MORNING
                                                      : slot.slot_id ===
                                                        "SLT0000002"
                                                      ? selectedValues.AFTERNOON
                                                      : selectedValues.EVENING
                                                  }
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    const slotType =
                                                      slot.slot_id ===
                                                      "SLT0000001"
                                                        ? "MORNING"
                                                        : slot.slot_id ===
                                                          "SLT0000002"
                                                        ? "AFTERNOON"
                                                        : "EVENING";
                                                    setSelectedValues(
                                                      (prevValues) => ({
                                                        ...prevValues,
                                                        [slotType]: value,
                                                      })
                                                    );
                                                    handleInputChange(
                                                      value,
                                                      index,
                                                      slot.slot_id,
                                                      "start_time"
                                                    );
                                                  }}
                                                >
                                                  {slot.slot_id ===
                                                  "SLT0000001" ? (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>

                                                      {MORNING?.map((item) => {
                                                        return (
                                                          <option
                                                            value={item?.val}
                                                            key={item.val}
                                                          >
                                                            {item?.time}
                                                          </option>
                                                        );
                                                      })}
                                                    </>
                                                  ) : slot.slot_id ===
                                                    "SLT0000002" ? (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>
                                                      {AFTERNOON?.map(
                                                        (item) => {
                                                          return (
                                                            <option
                                                              value={item?.val}
                                                            >
                                                              {item?.time}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>
                                                      {EVENING?.map((item) => {
                                                        return (
                                                          <option
                                                            value={item?.val}
                                                          >
                                                            {item?.time}
                                                          </option>
                                                        );
                                                      })}
                                                    </>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="col-md-4">
                                                <label htmlFor="">
                                                  End Time
                                                </label>
                                                <select
                                                  className="form-select"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e.target.value,
                                                      index,
                                                      slot.slot_id,
                                                      "end_time"
                                                    )
                                                  }
                                                >
                                                  {slot.slot_id ===
                                                  "SLT0000001" ? (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>
                                                      <option
                                                        selected
                                                        value="07:00:00"
                                                      >
                                                        07:00 AM
                                                      </option>
                                                      <option value="08:00:00">
                                                        08:00 AM
                                                      </option>
                                                      <option value="09:00:00">
                                                        09:00 AM
                                                      </option>
                                                      <option value="10:00:00">
                                                        10:00 AM
                                                      </option>
                                                      <option value="11:00:00">
                                                        11:00 AM
                                                      </option>
                                                      <option value="12:00:00">
                                                        12:00 PM
                                                      </option>
                                                    </>
                                                  ) : slot.slot_id ===
                                                    "SLT0000002" ? (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>
                                                      <option
                                                        selected
                                                        value="14:00:00"
                                                      >
                                                        02:00 PM
                                                      </option>
                                                      <option value="15:00:00">
                                                        03:00 PM
                                                      </option>
                                                      <option value="16:00:00">
                                                        04:00 PM
                                                      </option>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <option value="">
                                                        Please Select
                                                      </option>
                                                      <option
                                                        selected
                                                        value="18:00:00"
                                                      >
                                                        06:00 PM
                                                      </option>
                                                      <option value="19:00:00">
                                                        07:00 PM
                                                      </option>
                                                      <option value="20:00:00">
                                                        08:00 PM
                                                      </option>
                                                    </>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="col-md-3">
                                                <label htmlFor="">
                                                  Max Users
                                                </label>
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  placeholder="Enter Users"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e.target.value,
                                                      index,
                                                      slot.slot_id,
                                                      "max_users"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-1 d-flex align-items-end p-0 m-0">
                                                <FUSButton
                                                  className={
                                                    "action_button_style"
                                                  }
                                                  iconSrc="md"
                                                  iconName="MdDeleteOutline"
                                                  iconSize={16}
                                                  buttonType="danger"
                                                  onClick={() =>
                                                    handleDeleteSlot(
                                                      slot.slot_id,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </div>

                            <div className="row justify-content-between">
                              {allAttributesByCategory.length > 0 &&
                                allAttributesByCategory.map((attr, ind) => {
                                  return (
                                    <>
                                      <div className="col-md-6 ps-0 mt-3">
                                        <div className="form-group">
                                          <label htmlFor="">
                                            {attr.attribute_name}
                                          </label>
                                          <select
                                            name="product_attribute"
                                            id=""
                                            className="form-control"
                                            onChange={(e) =>
                                              setItemDetails((prev) => {
                                                const existingAttributeIndex =
                                                  prev.item_attributes.findIndex(
                                                    (attribute) =>
                                                      attribute.attribute_name ===
                                                      attr.attribute_name
                                                  );

                                                if (
                                                  existingAttributeIndex !== -1
                                                ) {
                                                  return {
                                                    ...prev,
                                                    item_attributes:
                                                      prev.item_attributes.map(
                                                        (attribute, index) =>
                                                          index ===
                                                          existingAttributeIndex
                                                            ? {
                                                                ...attribute,
                                                                attribute_value:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : attribute
                                                      ),
                                                  };
                                                }

                                                return {
                                                  ...prev,
                                                  item_attributes: [
                                                    ...prev.item_attributes,
                                                    {
                                                      attribute_name:
                                                        attr.attribute_name,
                                                      attribute_value:
                                                        e.target.value,
                                                    },
                                                  ],
                                                };
                                              })
                                            }
                                          >
                                            <option value="">Select</option>
                                            {attr?.default_value.map(
                                              (attrItem) => {
                                                return (
                                                  <>
                                                    <option value={attrItem}>
                                                      {attrItem}
                                                    </option>
                                                  </>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-md-12 ps-0  mt-3">
                                <div className="form-group">
                                  <label htmlFor="">Description</label>
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    id="description"
                                    rows="3"
                                    value={itemDetails.description}
                                    onChange={(e) =>
                                      setItemDetails((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                                {_.isEmpty(itemDetails.description) ||
                                _.isNil(itemDetails.description) ? (
                                  <span className="error-text">
                                    {errors?.description}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="mt-2">
                          <div class="row mt-3">
                            <div className="col-md-6 ps-0 m-0">
                              <FUSButton
                                className={"form-control py-2"}
                                labelText={"Cancel"}
                                buttonType="secondary"
                                type="button"
                                onClick={handleCancel}
                              />
                            </div>
                            <div className="col-md-6 pe-0 m-0">
                              <FUSButton
                                className={"form-control py-2"}
                                labelText={"Submit"}
                                buttonType="primary"
                                type="button"
                                onClick={() => handleSubmission()}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : toggleType === "addService" ? (
                      <>
                        <div className="row mt-2 ">
                          <div className="col-md-12 px-0 ">
                            <div class="form-group">
                              <label>Select LOB</label>
                              <select
                                id="lob"
                                className="form-select"
                                value={selectedLOB}
                                onChange={(e) => setSelectedLOB(e.target.value)}
                              >
                                <option value="">Select</option>
                                {lobOptions.length > 0 &&
                                  lobOptions.map((lob, index) => (
                                    <option key={index} value={lob.id}>
                                      {lob.lob}
                                    </option>
                                  ))}
                              </select>
                              {_.isEmpty(selectedLOB) ||
                              _.isNil(selectedLOB) ? (
                                <span className="error-text">
                                  {errors?.lob}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-12 px-0 mt-2">
                            <div class="form-group">
                              <label className="text-secondary">
                                Select Category
                              </label>
                              <select
                                id="subCategory"
                                className="form-select mt-1 text_style"
                                value={productOfService.category_id}
                                onChange={(e) =>
                                  setProductOfService((prev) => ({
                                    ...prev,
                                    category_id: e.target.value,
                                  }))
                                }
                                disabled={!selectedLOB}
                              >
                                <option value="">Select</option>
                                {productCategory.map((item) => (
                                  <option
                                    key={item.category_name}
                                    value={item.category_id}
                                  >
                                    {item.category_name}
                                  </option>
                                ))}
                              </select>
                              {_.isEmpty(productOfService.category_id) ||
                              _.isNil(productOfService.category_id) ? (
                                <span className="error-text">
                                  {errors?.category}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-12 px-0 mt-3">
                            <div>
                              <span className="text-secondary">
                                Product Name
                              </span>
                              <input
                                type="text"
                                id="product"
                                placeholder="Enter here..."
                                className="form-control text_style mt-1"
                                value={productOfService.product_name}
                                onChange={(e) =>
                                  setProductOfService((prev) => ({
                                    ...prev,
                                    product_name: e.target.value,
                                  }))
                                }
                                disabled={!productOfService.category_id}
                              />
                              {_.isEmpty(productOfService.product_name) ||
                              _.isNil(productOfService.product_name) ? (
                                <span className="error-text">
                                  {errors?.product_name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="row justify-content-between mt-2 ">
                            <div className="col-md-6 ps-0">
                              <FUSButton
                                className={"form-control"}
                                buttonType="secondary"
                                labelText={"Cancel"}
                                type="button"
                                onClick={handleCancel}
                              />
                            </div>
                            <div className="col-md-6 m-0 pe-0">
                              <FUSButton
                                className={"form-control"}
                                labelText={"Save"}
                                buttonType="primary"
                                type="button"
                                onClick={() => handleAddProduct()}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </FUSModal>
          )}
        </div>

        <input
          type="file"
          ref={fileRef}
          value=""
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <input
          type="file"
          ref={imageRef}
          value=""
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </LayoutContainer>
    </>
  );
};

export default Service;
