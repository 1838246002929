import React, { useEffect, useMemo, useState } from "react";

import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import VARIABLES from "../../services/variable.service";
import VendorService from "../../services/vendor.services";
import _ from "lodash";
import { pointer } from "d3-selection";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
import ProductService from "../../services/product.service";
const LocationImageMapping = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const token = TokenService.getUser();
  const [cities, setCities] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    MstService.getPermittedCity()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setCities(res?.data?.data);
        }
      })

      .catch((error) => {
        requestAndErrorHandler(error);
      });
  }, [toggle]);

  const uploadImage = async (e, city_id) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "CITY_IMAGE");
    formData.append("collection_data_id", city_id);
    formData.append("filename", file?.name);
    formData.append("mimetype", file.type);

    try {
      const response = await ProductService.uploadProductImage(formData);

      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setToggle((value) => !value);
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const deleteImage = async (item) => {
    const data = { image_name: item?.image_name, tag: item?.tag, flag: true };
    try {
      const result = await ProductService.deleteImage(data);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setToggle((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const filteredQuery = useMemo(() => {
    if (!_.isEmpty(cities)) {
      if (!_.isEmpty(searchQuery)) {
        const filterItems = cities.filter((option) => {
          if (
            !_.isUndefined(option?.city_code) &&
            !_.isEmpty(option?.city_code)
          ) {
            return option?.city_code
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }
        });
        return filterItems;
      } else {
        return cities;
      }
    } else {
      return [];
    }
  }, [searchQuery, cities]);

  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="Location Image Mapping"
    >
      <div>
        {token?.role?.role_id === VARIABLES.SUPER_USER && (
          <div className="row">
            <div className="col-md-2 mt-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search City Name.."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="px-4 mt-2 ">
          <table className="table table-bordered border border-1">
            <thead>
              <th>SR No</th>
              <th>City Name</th>
              <th>Upload Image</th>
            </thead>
            <tbody>
              {filteredQuery?.length > 0 ? (
                filteredQuery?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.city_code}</td>
                    <td>
                      {item?.image_name === null ? (
                        <input
                          type="file"
                          className={`form-control mt-1`}
                          name="image"
                          onChange={(e) => uploadImage(e, item?.row_id)}
                        />
                      ) : (
                        <div className="d-flex gap-3">
                          <img
                            src={item?.image_name}
                            className="rounded rounded-2"
                            alt="city_image"
                            style={{
                              width: "45px",
                              height: "35px",
                              cursor: pointer,
                            }}
                            onClick={() =>
                              setShowModal((prev) => ({
                                ...prev,
                                state: true,
                                type: "SHOW_IMAGE",
                                data: `${item?.image_name}`,
                              }))
                            }
                          />
                          <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => deleteImage(item)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={12}>
                    <div className="pb-5">
                      <FUSEmptyList subTitle={" Data Not Found"} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showModal.state && (
          <FUSModal
            title={showModal.type === "SHOW_IMAGE" && "Image"}
            showModal={showModal.state}
            size={"md"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            <div>
              <img
                src={showModal?.data}
                className="rounded rounded-2"
                alt="city_image"
                style={{ width: "100%", height: "300px" }}
              />
            </div>
          </FUSModal>
        )}
      </div>
    </LayoutContainer>
  );
};

export default LocationImageMapping;
